export const getErrorMessage = (error) => {
  let msg = error?.response?.data?.message;
  if (!msg) {
    msg = "出现了一些错误，请重试";
  } else {
    if (msg[0].message) {
      msg = msg[0].message;
    }
  }
  return msg;
};
