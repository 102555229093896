import axios from 'axios'
import { navigate } from 'gatsby'
import useNotification from '../hooks/use-notification'
import { getErrorMessage } from '../utils/error-messages'

// let baseURL = "http://192.168.8.29:9001";
let baseURL = 'https://iconme-server.partytest.link'

// deprecated
if (process.env.GATSBY_STORE_URL) {
  baseURL = process.env.GATSBY_STORE_URL
}

// takes precedence over GATSBY_STORE_URL
if (process.env.GATSBY_MEDUSA_BACKEND_URL) {
  baseURL = process.env.GATSBY_MEDUSA_BACKEND_URL
}

if (typeof window !== 'undefined') {
  const str = window.location.origin
  // if (str.includes('iconme-web.partytest.link')) {
  //   baseURL = `https://iconme-server.partytest.link`;
  // }
  if (str.includes('iconme-web.cirration.com')) {
    baseURL = `https://iconme-server.cirration.com`
  }
  if (str.includes('iconme-web.metaicons.link')) {
    baseURL = `https://iconme-server.gamehubs.link`
  }
  // 福州
  if (str.includes('iconme-web.minerhubs.link')) {
    baseURL = `https://iconme-server.minerhubs.link`
  }
}

const client = axios.create({ baseURL })
const checkStatus = (status, msg) => {
  let errMessage = '请求错误'
  switch (status) {
    case 400:
      errMessage = `${msg}`
      break
    case 401:
      errMessage = '用户没有权限'
      break
    case 403:
      errMessage = '访问被禁止'
      break
    // 404请求不存在
    case 404:
      errMessage = '网络请求错误，未找到该资源'
      break
    case 405:
      errMessage = '网络请求错误，请求方法未被允许'
      break
    case 408:
      errMessage = '网络请求超时'
      break
    case 500:
      errMessage = '服务器错误，请联系管理员'
      break
    case 501:
      errMessage = '网络未实现'
      break
    case 502:
      errMessage = '网络错误'
      break
    case 503:
      errMessage = '服务不可用'
      break
    case 504:
      errMessage = '网络超时'
      break
    case 505:
      errMessage = 'http版本不支持该请求'
      break
    default:
  }
  return errMessage
}

client.interceptors.response.use(
  /**
   * 如果您想要获取诸如头或状态之类的http信息
   * 就 return  response => response
   */

  /**
   *通过自定义代码确定请求状态
   *这里只是一个例子
   *您还可以通过HTTP状态代码判断状态
   */
  (response) => {
    return response
  },
  (error) => {
    if (getErrorMessage(error) === 'User Permission Denied') {
      const notification = useNotification()
      notification('错误', '您还未有访问权限', 'error')
      navigate('/login')
      return
    }
    return error
  }
)

export default function medusaRequest(method, path = '', payload = {}) {
  const options = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
    json: true,
  }
  // return new Promise((resolve, reject) => {
  //   client(options).then((res) => {
  //     console.log("-----res------", res);
  //     resolve(res);
  //   });
  // });
  return client(options)
}

export const multipartRequest = (path, payload) => {
  const options = {
    withCredentials: true,
    headers: {
      'content-type': 'multipart/form-data',
    },
  }

  return client.post(path, payload, options)
}
