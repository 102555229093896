import { MedusaProvider } from 'medusa-react'
import React from 'react'
import 'antd/dist/antd.css'
import './src/assets/styles/emoji-picker.css'
import './src/assets/styles/global.css'
import { LayeredModalProvider } from './src/components/molecules/modal/layered-modal'
import { SteppedProvider } from './src/components/molecules/modal/stepped-modal'
import { AccountProvider } from './src/context/account'
import { CacheProvider } from './src/context/cache'
import { InterfaceProvider } from './src/context/interface'
import { baseURL, queryClient } from './src/services/config'

export const wrapPageElement = ({ element }) => {
  return (
    <MedusaProvider
      baseUrl={baseURL}
      queryClientProviderProps={{
        client: queryClient,
      }}
    >
      <CacheProvider>
        <AccountProvider>
          <InterfaceProvider>
            <SteppedProvider>
              <LayeredModalProvider>{element}</LayeredModalProvider>
            </SteppedProvider>
          </InterfaceProvider>
        </AccountProvider>
      </CacheProvider>
    </MedusaProvider>
  )
}
